/* You can add global styles to this file, and also import other style files */
@import 'primeicons/primeicons.css';
@import 'primeflex/primeflex.min.css';

/* Global default theme variables */
$primary-color: #003DA5;
$secondary-color: #0d2757;
$default-font-size: 14px;
$text-color: #051B44;
$mxn-green:#3FC75D;
$mxn-red:#FF637B;
$mxn-blue:#00b0ff;
$primary-font:"Inter", sans-serif;
$border:#79859F;
body{
    margin: 0;
    padding: 0;
    font-family: $primary-font;
}
/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #00000044; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #00000055; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background:#00000066; 
  }
  ::placeholder {
    color: #051B4457;
    opacity: 1; /* Firefox */
  }
  
  ::-ms-input-placeholder { /* Edge 12-18 */
    color: #051B4457;
  }
button{
    &:focus{
        box-shadow: none;
    }
    &:disabled{
        opacity: 0.5;
    }
}
.p-dropdown{
    border-radius: 8px;
}
.btn{
    padding: 10px 12px;
    border-radius: 8px;
    border: 1px solid $primary-color;
    background:$primary-color;
    color: #fff;
    font-size: 16px;
}
.btn-sm{
    padding: 8px 12px;
    border-radius: 8px;
    border: 1px solid $primary-color;
    background:$primary-color;
    color: #fff;
    font-size: 12px;
    border: none;
    outline: none;
    cursor: pointer;
}
.p-multiselect{
    width:262px;
    height:44px;
    border-radius: 8px;
    border: 1px solid #ccc;
    .p-multiselect-item{
        border-bottom: 1px solid #C3CFD9;
        height: 44px;
        padding: 12px 16px;
        color: #465471;
        font-size: 14px;
        font-weight: 400;
         
    }
}
.p-inputwrapper-focus{
    .p-multiselect-trigger{
        rotate: 180deg;
    }
}
.p-checkbox{
    .p-checkbox-box{
        border:2px solid #C3CFD9;
        &.p-highlight{
            border-color: $primary-color;
            background: #005EFF1A;
            .p-checkbox-icon{
                color: $primary-color;
            }
        }
    }
}
.p-calendar{
    .p-inputtext{
        background: url(../public/assets/calendar-bg.svg) no-repeat 15px center;  
        padding-left: 45px;
        border-radius:8px;
        border: 1px solid #D0D5DD;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        border: 1px solid #D0D5DD;
        height: 44px;
    }
}
// .p-datepicker{

// }

// Settings sidebar button css
.max-setting-btn{
    position: relative;
    background: transparent;
    .p-button{
        background: transparent;
        width: 37px;
        height:34px;
        border-radius: 4px;
        border: 1px solid #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        padding: 0;
        svg{
            fill: white;
        }
        &:hover{
            background: white;
            svg{fill: $primary-color;}
        }   
        @media (max-width:992px) {
            border: none;
            svg{
                width: 20px;
                height: 20px;
            }
        }
    }
}

// dropdown overlay css
.mxn-overlay{
    position: relative;
    .mxn-overlay-ddm{
        position: absolute;
        top: 100%;
        right: 0;
        background:transparent;
        display: none;
        padding-top: 14px;
        z-index: 1000;
        &:before{
            content: "";
            width: 20px;
            height: 10px;
            display: block;
            background: url(../public/assets/overlay-tip.svg) no-repeat center;
            position: absolute;
            right: 5px;
            top: 5px;
        }
        .mxn-overlay-items{
            background-color: #fff;
            border-radius: 4px;
            width: 190px;
            box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.20);
            padding:4px 8px;
        }
        ul{
            padding: 0;
            margin: 0;
            list-style: none;
            li{
                padding: 8px 0;
                a{
                    color:$text-color;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px; 
                    letter-spacing: -0.42px;
                    text-decoration: none;
                }
            }
        }
    }   
    svg{
        fill: white;
    }
    &:hover{
        svg{fill: $primary-color;}
        .mxn-overlay-ddm{
            display: block;
        }
    }
}

// Notificatins tabs accordion css
.mxn-notify-accordion{
    height: calc(100vh - 315px);
    overflow: auto;
    padding-right: 10px;
    margin-bottom: 25px;
    @media (max-width:1095px) {
        height: auto;
    }
    .p-accordion {
        .p-accordion-tab{
            transition: all 0.5s;
            border: 1px solid white;
            &.p-accordion-tab-active{
                border: 1px solid $primary-color;
            }
            .p-accordion-header {
                background: #fff;
                border-radius: 6px;
                &.p-highlight{
                    background: #6767671a;
                    .acc-tab-icon{
                        rotate: 180deg;
                    }
                }
                &:hover{
                    background: #6767671a;
                }
                .p-accordion-header-link{
                    background:transparent;    
                    padding: 10px 16px 10px 12px;   
                    .p-accordion-toggle-icon{
                        display: none;
                    }
                    .mxn-notify-title,.mxn-notify-Pname{
                        font-size: 20px;
                        font-weight: 500;
                        line-height: 24.2px;
                        letter-spacing: -0.03em;
                        color: $text-color;
                    }
                    .mxn-notify-act{
                        display: flex;
                        align-items: center;
                        gap: 12px;
                        @media (max-width:992px){
                            gap:18px;
                        }
                        .mxn-status-tooltip{
                            .mxn-overlay-ddm{
                                 top: 80%;
                                 right: -5px;
                                 // // &:hover{
                                //     display: none;   
                                //    }
                                // &:before{
                                //     display: none !important;
                                // }    
                                .mxn-overlay-items{
                                    ul{
                                        li{
                                            padding-left: 5px;
                                            display: flex;
                                            gap:4px;
                                            color: $text-color;
                                            font-size: 14px;
                                            font-weight: 400;
                                            line-height: 20px;
                                            border-radius: 20px;
                                            .mxn-sortby-icon{
                                                width: 18px;
                                            }
                                            &.discharge{
                                                background: #00C8531A;
                                                color: $mxn-green;
                                            }
                                            &.inprogress{
                                                background: #00B0FF1A;
                                                color: $mxn-blue;
                                            }
                                            &.notstarted{
                                                background: #FF637B1A;
                                                color: $mxn-red;
                                            }
                                            // img{
                                            //     filter: grayscale(1);
                                            // }
                                            &.active{
                                                img{
                                                    filter: grayscale(0);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .mxn-notify-commints-tooltip{
                            img{
                                @media (max-width:992px){
                                    width: 20px;
                                }
                            }
                            .mxn-overlay-ddm{
                                right: -5px;
                                &:hover{
                                 display: none;   
                                }
                                .mxn-overlay-items{
                                    width: 272px;
                                    padding: 0;
                                    ul{
                                        li{
                                            display: flex;
                                            align-items: start;
                                            gap: 8px;
                                            color: $text-color;
                                            font-size: 12px;
                                            font-weight: 400;
                                            line-height: 16px;
                                            border-bottom: 1px solid #E1E8EE;
                                            padding:8px ;
                                            &:last-child{
                                                border: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .max-read-unreadIcon{
                            width: 24px;
                            height: 24px;
                            text-align: center;
                        }
                    }
                    .mxn-notify-Pgen{
                        font-size: 20px;
                        font-weight: 400;
                        line-height: 24.2px;
                        letter-spacing: -0.03em;
                        color: $text-color;
                        span{
                            color: #79859F;
                            font-weight: 500;
                        }
                    }
                    .mxn-notify-Pdob{
                        color: #79859F;
                        font-size: 16px;
                        font-weight: 400;
                        letter-spacing: -0.03em;
                        padding-top: 3px;
                        min-width: 145px;
                    }
                    .mxn-notify-provider{
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 19.36px;
                        letter-spacing: -0.03em;
                        text-align: left;
                    }
                    .mxn-notify-provider-small{
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 19.36px;
                        letter-spacing: -0.03em;
                        text-align: right;
                    }
                }
            }
            .p-accordion-content{
                padding: 14px 16px 5px 13px;
                .mb-10{
                    margin-bottom: 10px;
                }
                .mxn-notify-Dlabel{
                    gap: 11px;
                    display: flex;
                    justify-content: start;
                    align-items: center;
                    color: #79859F;
                    font-family: Inter;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: -0.03em;
                }
                .mxn-notify-Dinfo{
                    font-size: 16px;
                    font-weight: 400;
                    letter-spacing: -0.03em;
                    color: $text-color;
                    text-align: right;
                    .mxn-notify-bubbleSolid{
                        min-width:24px;
                        height: 19px;
                        padding: 2px 7px 2px 7px;
                        gap: 8px;
                        border-radius: 20px;
                        font-size: 12px;
                        background: $mxn-green;
                        color: white;
                    }
                }
            }
        }
    }
}
.acc-tab-notstarted{
    border-left: 4px solid $mxn-red !important;
    border-radius: 8px;
}
.acc-tab-inprogress{
    border-left: 4px solid $mxn-blue !important;
    border-radius: 8px;
}
.acc-tab-completed{
    border-left: 4px solid $mxn-green !important;
    border-radius: 8px;
}
.mxn-right-col{
    height: calc(100vh - 115px);
    overflow: auto;
    background: #f3f3f3;
    @media (max-width:992px){
        display: none;
    }
    &.mob-view{
        @media (max-width:992px){
            display: block;
            position: fixed;
            top: 0;
            width: calc(100% - 20px);
            min-width: 520px;
            height: 100vh;
            z-index: 999;
            left: 8px;
        }    
    }
}
.max-acc-unread{
    .p-accordion-header-link{
        *{
            font-weight: 700 !important;
        }
    }
}
.mxn-notify-sorting{
    position: relative;
    .p-button{
        width:  32px;
        height: 32px;
        border-radius: 4px;
        background: #F5F6F9 url(../public/assets/filter-2.svg) no-repeat center;
        cursor: pointer;
        border: none;
    }
    .mxn-overlay-ddm{
        right: 5px;
    }
    .mxn-overlay-items{
        ul{
            li{
                display: flex;
                justify-content: start;
                align-items: center;
                gap: 8px;
                color:#79859F;
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
                cursor: pointer;
                filter: grayscale(1);
                .mxn-sortby-icon{
                    width: 16px;
                    height: 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    i,em{
                        font-size: 10px;
                    }
                }     
                &:hover{
                    color: $primary-color;
                    filter: grayscale(0);
                }
                .active{
                    color: $primary-color;
                    filter: grayscale(0);
                }
            }
            .li-active{
                display: flex;
                justify-content: start;
                align-items: center;
                gap: 8px;
                color: $primary-color;
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
                cursor: pointer;
                filter: grayscale(0);
                .mxn-sortby-icon{
                    width: 16px;
                    height: 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    i,em{
                        font-size: 10px;
                    }
                }     
            }
        }
    }
}

.p-datatable {
    border: 1px solid #C3CFD9;
    border-radius: 8px;
    overflow: hidden;
    .p-datatable-thead {
        tr{
            th{
                color: $text-color;
                font-size: 16px;
                font-weight: 600;
                background: #F5F6F9;
                &:first-child{
                    border-radius: 8px 0 0 0;
                }
                &:last-child{
                    border-radius:0 8px 0 0;
                }
            }
        }
    }
    .p-datatable-tbody{
        tr{
            &:last-child{
                td{
                    &:first-child{
                        border-radius:  0 0 0 8px;
                    }
                    &:last-child{
                        border-radius:0 0 8px 0;
                    }
                }
            }
            td{
                border: none;
                &.mxn-td-label{
                    font-weight: 400 !important;
                }
                .p-datatable{
                    border: none;
                    // border-bottom: 1px solid #C3CFD9;
                    border-radius: 0;
                    padding:0 9px;
                    .p-datatable-thead {
                        tr{
                            th{
                                border-bottom: none;
                                &:first-child{
                                    border-radius: 8px 0 0 8px;
                                }
                                &:last-child{
                                    border-radius:0 8px 8px 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.border-state{
        .p-datatable-thead {
            tr{
                th{
                    border-top: 1px  solid #e5e7eb;
                    &:first-child{
                        border-radius: 0;
                    }
                    &:last-child{
                        border-radius:0;
                    }
                }
            }
        }
        .p-datatable-tbody{
            tr{
                &:last-child{
                    td{
                        &:first-child{
                            border-radius:  0 
                        }
                        &:last-child{
                            border-radius: 0;
                        }
                    }
                }
            }
        }
    }
}
.p-sidebar {
    @media (max-width:992px){
        width:100% !important;
    }
    .p-sidebar-header {
        background: #F5FBFF;
        border: 1px solid #DCE1EB;
        height: 80px;
        span{
            color: $primary-color;
            font-size: 24px !important;
            font-weight: 600 !important;
        }
        .p-sidebar-close{
            border-radius: 6px;
            border: 1px solid $primary-color;
            color: $primary-color;
            width: 24px;
            height: 24px;
            &:hover{
                background: $primary-color;
                svg{
                    path{
                        fill:#fff !important;
                    }
                }
            }
            svg{
                width: 12px;
                height: 12px;
            }
        }
    }
    .p-sidebar-content{
        padding: 16px;
        .max-usersetting-prf{
            display: flex;
            flex-direction: column;
            padding: 16px;
            justify-content: space-between;
            align-items: flex-start;
            border: 1px solid #C3CFD9;
            border-radius: 8px;
            margin-bottom: 10px;
            color: $text-color;
            font-size: 16px;
            font-weight: 600;
            position: relative;
            .switch-label{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: end;
                align-items: center;
                padding-right: 16px;
                cursor: pointer;
            }
            &:first-child{
                margin-bottom: 16px;
            }
        }
        .max-usersetting-prf2{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            color: $text-color;
            font-size: 16px;
            font-weight: 600;
            // position: relative;
            .switch-label{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 50px;
                display: flex;
                justify-content: end;
                align-items: start;
                padding-right: 16px;
                padding-top: 16px;
                cursor: pointer;
            }
            .headerWrapper{
                display:flex;
                flex-direction: column;
            }
        }
    }
    .p-sidebar-footer{
        border-top: 1px solid #C3CFD9;
    }
    p-accordiontab{
        &:last-child{
            .p-accordion-content{
                border-bottom: none !important;
            }
        }
    }
}
.optionsWrapper{
    display: flex;
    color: #656e83;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.42px;
}
.subtitle{
    color: #79859F;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.42px;
    margin-top: 5px;
    margin-bottom: 7px;
}
.mxn-filter-sidebar{
    .p-sidebar-content {
        padding: 0;
        .max-filter-tabs{
            .p-tabview-panels{
                padding: 0;
                .p-accordion-header-link{
                    background: #fff;
                    border-radius: 0;
                    border: none;
                    border-bottom: 1px solid #C3CFD9;
                    justify-content: space-between;
                    padding: 20px 16px;
                    color: $text-color;
                    font-size: 16px;
                    font-weight: 600;
                    .p-accordion-header-text{
                        display: none;
                    }
                    .p-icon-wrapper{
                        order: 1;
                        rotate: 90deg;
                        color: #79859F;
                    }
                    .mxn-Facc-title{
                        order: 0;
                        color: $text-color;
                        font-size: 16px;
                        font-weight: 600;
                    }
                }
                
                .p-accordion-tab{
                    border-bottom:none;
                    border-radius: 0;
                    margin-bottom: 0;
                    .p-toggleable-content{
                        // border-bottom: 1px solid #C3CFD9;
                        border-radius: 0;    
                        height: 0;
                        // overflow: hidden;
                        visibility: hidden;
                        transition: all 0.5s;
                        .p-accordion-content{
                            border-bottom: 1px solid #C3CFD9;
                            border-radius: 0;
                        }
                    }
                    &.p-accordion-tab-active{
                        .p-accordion-header-link{
                            .p-icon-wrapper{
                                rotate: 180deg;
                            }
                        }
                        .p-toggleable-content{
                            height: auto;
                            visibility: visible;
                            transition: all 0.5s;
                            // overflow: visible;
                        }
                    }
                }
            }
        }
    }
}
.p-inputtext{
    max-width: 100%;
}
.max-cancel-btn{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 56px;
    padding: 19px 0px 18px 0px;
    justify-content: center;
    align-items: center;
    border-radius:8px;
    background: rgba(225, 232, 238, 0.60);
    border-color: #fff;
    color: $text-color;
    font-size: 16px;
    font-weight: 400;
    .p-button-label{
        font-weight: 400;;
    }
    &:hover{
        border-color: $primary-color;
    }
}
.max-fltrBtn-wrapper{
    width: 274px;
    height: 56px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #003DA5;
    align-items: center;
    position: relative;
    @media (max-width:992px) {
        width: 100%;
    }
    .max-FltApply-btn{
        background: transparent;
        width: 100%;
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        border: none;
        height: 56px;
        .p-button-label{
            font-weight: 400;;
        }
    }
    .max-FltSave-btnicon{
        height: 24px;
        border-left: 1px solid #fff;
        padding-left: 16px;
        color: #fff;
        margin-right: 18px;
        display: flex;
        align-items: center;
        transition:all 0.3s;
    }
    .max-FltSave-tooltip{
        position: absolute;
        top: -45px;
        right: 0;
        padding-bottom:10px;
        display: none;
        cursor: pointer;
        .max-FltSave-btn{
            width: 99px;
            height: 36px;
            border-radius: 4px;
            background: #FFF;
            box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
            color: $text-color;
            font-size: 12px;
            font-weight: 400;
            line-height: 36px;
            padding: 0;
            border: none;
            .p-button-label{
                font-weight: 400;;
            }
        }
    }
    &:hover{
        background: #1052C3;
        .max-FltSave-tooltip{
            display: block;
        }
        .max-FltSave-btnicon{
            i,em{
                rotate: 180deg;
            }
        }
    }
}
.max-filter-actBtn{
    display: inline-flex;
    height: 28px;
    padding: 4px 14px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid $primary-color;
    background: #fff;
    cursor: pointer;
    color: $primary-color;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    &:hover{
        background: $primary-color;
        color: #fff;
    }
    .p-button-label{
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
    }
}
.ngx-slider[disabled] {
    opacity: 0.5;
}
.ngx-slider.animate,.ngx-slider{
    .ngx-slider-pointer{
        background: #fff !important;
        border:1px solid $primary-color;
        width: 24px !important;
        height: 24px !important;
        top: -10px !important;
        box-shadow: 0px 2px 4px rgba(16, 24, 40, 0.06) , 0px 4px 8px rgba(16, 24, 40, 0.10);
        &:after{
            display: none;
        }
        &.ngx-slider-active{
            width: 24px;
            height: 24px;
            background: $primary-color;
            &:after{
                display: none;
            }
        }
        &:hover{
            background: $primary-color;
        }
    }
    .ngx-slider-bar{
        height: 8px !important;
        border-radius: 50px !important;
    }
    .ngx-slider-selection{
        background: $primary-color !important;
    }
    .ngx-slider-floor,.ngx-slider-ceil{
        display: none !important;
    }
    .ngx-slider-model-value,.ngx-slider-model-high{
        border-radius: 8px;
        background:#FFF;
        box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.15);
        width: 38px;
        height: 34px;
        top: -55px;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        &:before{
            content: "";
            width: 20px;
            height: 10px;
            display: block;
            background: url(../public/assets/overlay-tip.svg) no-repeat center;
            position: absolute;
            right: 9px;
            top: 34px;
            rotate: 180deg;
        }
    }
}
.p-radiobutton{
    width: 16px;
    height: 16px;
    .p-radiobutton-box{
        width: 16px;
        height: 16px;
        flex: 16px 0 0;
        border-radius: 120px;
        border: 1px solid #D8DAE5;
        background: #FFF;
        &.p-highlight{
            outline: none;
            box-shadow: none;
            background: $primary-color;
            border-color: $primary-color;
            .p-radiobutton-icon{
                width: 8px;
                height: 8px;
                background: #fff;
            }
        }  
    }
}

.max-confirmation-dialog{
    background-color: #fff;
    border-radius: 6px;
    width: 640px;
    padding-top: 24px;
    .p-dialog-header{
        display: none;
    }
    .p-dialog-content{
        padding: 0;
        .mxn-popup-icon{
            flex: 40px 0 0;
            .mxn-warning{
                width: 39px;
                height: 39px;
                border-radius: 50%;
                background: #F5F6F9;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        h3{
            color: #1F2937;
            font-size: 20px;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: -0.25px;
        }
        p{
            color: $text-color;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: -0.25px;
        }
        .max-dialog-footer{
            padding: 12px;
            margin-top: 24px;
            border-radius: 0px 0px 6px 6px;
            background: #F5F6F9;
            .max-dialog-cancel-btn{
                .p-button{
                    padding: 14px 18px;
                    border-radius: 6px;
                    border: 1px solid #C3CFD9;
                    background: transparent;
                    .p-button-label{
                        color: #79859F;
                        text-align: center;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 14px;
                        letter-spacing: -0.28px;
                    }
                }
            }
            .max-dialog-Confirm-btn{
                .p-button{
                    display: flex;
                    padding: 14px 18px;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    border-radius: 6px;
                    background: $mxn-red;
                    border-color: $mxn-red;
                    .p-button-label{
                        color: #FFF;
                        text-align: center;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 14px;
                        letter-spacing: -0.28px;
                    }
                }
            }
        }
    }
}

.mxn-filter-sidebar{
    &.p-sidebar{
        .p-sidebar-header{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            background: transparent;
            .p-sidebar-close{
                z-index: 99;
            }
        }
        
        .p-tabview{
            .p-tabview-nav-container{
                padding-left: 24px;
                background-color: #F5FBFF;
                position: sticky;
                z-index: 9;
                top: 0;
                border: 1px solid #e5e7eb;
                .p-tabview-nav{
                    background: transparent;
                    border: none;
                    li{
                       height: 80px;
                       padding: 25px 0;
                       .p-tabview-nav-link{
                           color: #79859F;
                           font-size: 24px;
                           font-weight: 600;
                           padding: 0;
                           border-bottom: none;
                           background: transparent;
                       }
                       &.p-highlight{
                           .p-tabview-nav-link{
                               color: $primary-color;
                           }
                       } 
                       &:first-child{
                           .p-tabview-nav-link{
                               border-right: 2px solid #C3CFD9;
                               margin-right: 10px;
                               padding-right: 10px;
                               border-radius: 0;
                           }
                       }
                   }
               }
            }
            
        }
    }    
}
.mxn-notifyconts-row{
    .mxn-notify-commentEdit{
        display: none;
    }
    &:hover{
        .mxn-notify-commentEdit{
            display: flex;
        }
    }
}
.max-nobordered-panel{
    .mxn-inner-panel{
        padding: 0 !important;
        border: none !important;
    }
}
.mxn-inner-panel{
    @media (max-width:992px) {
        overflow: auto;
        .mxn-detail-table-wraper{
            min-width: max-content;
        }
    }
}
.max-fulltable{
    .p-datatable {
        border: none;
        border-radius: 0;
        overflow: hidden;
        .p-datatable-thead {
            tr{
                th{
                    background: #F5F6F9;
                    border-top:1px solid #C3CFD9;;
                    border-bottom:1px solid #C3CFD9;
                    &:first-child{
                        border-radius:  0;
                    }
                    &:last-child{
                        border-radius:0;
                    }
                }
            }
        }
    }   
}
.p-datepicker{
    width: 298px;
    table{
        margin: auto;
        th{
            width: 32px;
            height: 36px;
            padding: 0;
            span{
                width: 32px;
                height: 36px;
                font-family: Arial;
                font-size: 10px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: -0.5px;
                color: #8F95B2;
            }
        }
        td{
            width: 32px;
            height: 36px;
            padding: 0;
            border-spacing: 0;
            span{
                width: 41px;
                height: 40px;
                font-family: Arial;
                font-size: 14px;
                font-weight: 400;
                line-height: 16px;
                text-align: left;
                color: $text-color;
                border-radius: 0;
                &:hover{
                    background: #F6F8FF;
                }
                &.p-highlight{
                    background: $primary-color !important;
                    color: #fff;   
                }
            }
            &.p-datepicker-today{
                span{
                    background: #F6F8FF;
                }
            } 
        }
    }
    .p-datepicker-header{
        .p-datepicker-title{
            order: 0;
            margin: 0;
            flex: 1 0 0;
            button{
                font-size: 18px;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: -0.5px;
                text-align: left;
                color: #051B44;
                margin: 0;
                padding: 0  5px 0 0;
            }
        }
        .p-datepicker-prev{
            order: 1;
            background: url(../public/assets/perv-icon.svg) no-repeat center center;
            width: 32px;
            height: 32px;
            border-radius: 6px;
            border: 1px solid #D8DAE5;
            margin-right: 8px;
        }
        .p-datepicker-next{
            order: 2;
            background: url(../public/assets/perv-icon.svg) no-repeat center center;
            width: 32px;
            height: 32px;
            border-radius: 6px;
            border: 1px solid #D8DAE5;
            rotate: 180deg;
        }
        .p-icon-wrapper{
            display: none;
        }
    }
} 


.calendar-container {
    display: flex;
    align-items: flex-start;
    width: 450px;
    background: #fff;
    border-radius: var(--Radius-8, 8px);
    box-shadow: 0px 4px 8px 0px rgba(5, 27, 68, 0.08);
    margin: auto;
    margin-top: 10px;
    .p-datepicker{
        border: none;
    }
    .calendar-sidebar {
        gap: 4px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .p-button{
            &.mxn-clndar-btn{
                background: transparent;
                border: none;
                display: flex;
                height: 32px;
                padding: 4px 20px;
                justify-content: center;
                align-items: center;
                border-radius: 4px;
                text-align: center;
                .p-button-label{
                    color: #79859F;
                    font-family: Arial;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px; /* 171.429% */
                    letter-spacing: -0.5px;
                }
                &:hover,&.active{
                    background: $primary-color;
                    .p-button-label{
                        color: #fff;
                    }
                }
            }
        }
    }
  }
  .mxn-advanceFiltersrow{
    margin-bottom: 100px;
  }
  .p-dropdown .p-dropdown-clear-icon{
    color: $mxn-red;
  }
  .mxn-showRows{
    .p-dropdown-panel{
        .p-dropdown-items {
            padding: 0;
            border: 1px solid #C3CFD9;
            border-radius: 8px;
            .p-element{
                .p-dropdown-item{
                    padding: 8px 13px;
                    border-bottom: 1px solid #C3CFD9;
                    span{
                        color: #051B44;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 20px;
                    }
                }
                &:last-child{
                    .p-dropdown-item{ border: none;}
                }
            }
            
        }
    }
    .p-dropdown-trigger{
        background: url(../public/assets/arrow-down.svg) no-repeat center center;
        .p-icon-wrapper {
            display: none;
        }
    }
  }
  .mxn-advanceFilters-notes{
    display: flex;
    align-items: center;
    gap: 5px;
    color:#051B44;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    margin-top: 40px;
    margin-bottom: 8px;
}
.pagination-rows{
    .p-dropdown{
        height: 38px;
        .p-dropdown-label{
            padding:9px 15px;
        }
    }
}

.invalid {
    .p-inputtext{
        border: 1px solid red !important;
    }
    .p-dropdown{
        border: 1px solid red !important;
        .p-inputtext{
            border: none !important;
        }
    }
}
.mxn-portrait-overlay {
    display: none;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: #000000eb;
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    @media (max-width:480px) {
        display: flex;
    }
    .mxn-portrait-mode{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;
        padding: 20px;
        h3{
            font-size: 20px;
            color: #fff;
            margin: 0;
        }
        p{
            font-size: 16px;
            color: #fff;
            text-align: center;
            margin: 0;
        }
    }
}

::ng-deep .p-tooltip {
    background-color: white !important;
    color: white;
    font-size: 0.9em;
    width: Fixed (324px)px;
    height: Fixed (31px)px;
    top: -4px;
    left: 34px;
    padding: 0px 8px 0px 8px;
    gap: 8px;
    border-radius: 4px 0px 0px 0px;
    opacity: 0px;
}

.mxn-advanceFiltersrow .p-overlay{
    max-width: 100%;
}